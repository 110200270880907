.container {
}

.containerX {
  margin: 130px auto 0px;
  width: 100%;
}

.tableChart {
  display: grid;
  grid-template-columns: 50% 50%;
}

.innerBody {
  width: 95%;
  margin: 30px auto;
}

@media (max-width: 768px) {
  .tableChart {
    grid-template-columns: 100%;
  }
}
